var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"fulfiller-order","bladeName":"fulfiller-orders","bladesData":_vm.bladesData,"canSearchLocal":"","dependantBlades":[{ bladeName: 'fulfiller-order' }],"flexColumn":"","getNewBladeData":_vm.getNewBladeData,"getSelectBladeData":_vm.getSelectBladeData,"getParams":function (item) { return { includeDetails: false, customerIDs: item.data == null ? null : item.data.customerID } },"headers":[
        { text: 'CO#', value: 'customerOrderNumber', subtitle: 2, prefix: 'CO#', searchable: true },
        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: 1, prefix: 'Due: ' },
        { text: 'Seller', value: 'seller.companyName', title: 2, searchable: true },
        { text: 'Buyer', value: 'buyer.companyName', title: 1, searchable: true },
        { text: 'Destination', value: 'location', textFilter: 'toLocationLine', subtitle: 0, hide: true }],"loadingMsg":_vm.loadingMsg,"navigation":"fulfiller-orders","onPullSuccessAsync":_vm.pullOrders,"selectKey":"isSelectedToProcess","searchProps":['customerOrderNumber','buyer.companyName','seller.companyName'],"selectMany":_vm.selectMany,"title":"Orders To Fulfill","useServerPagination":""},scopedSlots:_vm._u([{key:"settings",fn:function(ref){
        var items = ref.items;
return [(!_vm.selectMany)?_c('v-list-item',{on:{"click":function($event){return _vm.selectUnprocessedOrders(items)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-multiple-outline")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Select Unprocessed Orders")])],1)],1):_c('v-list-item',{on:{"click":function($event){_vm.selectMany = false}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-multiple-outline")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Hide Selection")])],1)],1),(_vm.$canView('supplier-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"supplier-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-divider'),_c('v-subheader',[_vm._v("Actions "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":"","to":{ name: 'ordering-actions' },"title":"View Past Actions"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-history")])],1)],1),_c('v-divider'),_c('v-subheader',[_vm._v("Settings")])]},proxy:true}],null,true)}):_vm._e(),(_vm.$canView('courier-settings'))?_c('BT-Entity',{attrs:{"ignoreID":"","inline":"","navigation":"courier-settings","single":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var save = ref.save;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Courier Selecting")])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('v-btn',{attrs:{"small":"","icon":"","to":{ name: 'courier-pointers' },"title":"Manage Automatic Courier Selecting"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-routes")])],1),_c('BT-Toggle-Trigger-Icon',{attrs:{"disabled":!_vm.isGlobalSetting(item.courierSelectionGuideAsSupplier)},on:{"change":save},model:{value:(item.courierSelectionTriggerAsSupplier),callback:function ($$v) {_vm.$set(item, "courierSelectionTriggerAsSupplier", $$v)},expression:"item.courierSelectionTriggerAsSupplier"}}),_c('BT-Toggle-Guide-Icon',{on:{"change":save},model:{value:(item.courierSelectionGuideAsSupplier),callback:function ($$v) {_vm.$set(item, "courierSelectionGuideAsSupplier", $$v)},expression:"item.courierSelectionGuideAsSupplier"}})],1)],1)],1)]}}],null,true)}):_vm._e()]}},{key:"itemActions",fn:function(ref){
        var item = ref.item;
return [(item.isConfirmed && item.fulfillerID != null && !item.isDispatched)?_c('BT-Single-Action',{attrs:{"loading":item.loadingCount > 0,"title":"Proceed to next step of purchase order - a stock consignment"},on:{"click":function($event){return _vm.processPurchaseOrder(item)}}}):_vm._e()]}},{key:"selectHeader",fn:function(ref){
        var items = ref.items;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.processUnprocessedCustomerOrders(items)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right-circle")])],1)]}},{key:"selectItem",fn:function(ref){
        var item = ref.item;
return [(!item.isDispatched)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();item.isSelectedToProcess = !item.isSelectedToProcess}}},[(item.isSelectedToProcess)?_c('v-icon',{staticClass:"success--text",attrs:{"small":""}},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"error--text",attrs:{"small":""}},[_vm._v("mdi-close")])],1):_vm._e()]}}])},[_c('template',{slot:"actions"},[_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }