<template>
    <BT-Blade-Items
        addBladeName="fulfiller-order"
        bladeName="fulfiller-orders"
        :bladesData="bladesData"
        canSearchLocal
        :dependantBlades="[{ bladeName: 'fulfiller-order' }]"
        flexColumn
        :getNewBladeData="getNewBladeData"
        :getSelectBladeData="getSelectBladeData"
        :getParams="item => { return { includeDetails: false, customerIDs: item.data == null ? null : item.data.customerID } }"
        :headers="[
            { text: 'CO#', value: 'customerOrderNumber', subtitle: 2, prefix: 'CO#', searchable: true },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: 1, prefix: 'Due: ' },
            { text: 'Seller', value: 'seller.companyName', title: 2, searchable: true },
            { text: 'Buyer', value: 'buyer.companyName', title: 1, searchable: true },
            { text: 'Destination', value: 'location', textFilter: 'toLocationLine', subtitle: 0, hide: true }]"
        :loadingMsg="loadingMsg"
        navigation="fulfiller-orders"
        :onPullSuccessAsync="pullOrders"
        selectKey="isSelectedToProcess"
        :searchProps="['customerOrderNumber','buyer.companyName','seller.companyName']"
        :selectMany="selectMany"
        title="Orders To Fulfill"
        useServerPagination>
        <template v-slot:settings="{ items }">
            <v-list-item v-if="!selectMany" @click="selectUnprocessedOrders(items)">
                <v-list-item-icon>
                    <v-icon small>mdi-checkbox-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Select Unprocessed Orders</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="selectMany = false">
                <v-list-item-icon>
                    <v-icon small>mdi-checkbox-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Hide Selection</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <BT-Entity
                v-if="$canView('supplier-settings')"
                ignoreID
                inline
                navigation="supplier-settings"
                single>
                <template v-slot>
                    <v-divider />
                    <v-subheader>Actions <v-spacer />
                        <v-btn small icon :to="{ name: 'ordering-actions' }" title="View Past Actions">
                            <v-icon small>mdi-history</v-icon>
                        </v-btn>
                    </v-subheader>
                    <!-- <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Process Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'customer-order-schedules' }" title="Manage Schedules">
                                    <v-icon small>mdi-timer-cog</v-icon>
                                </v-btn>
                                <BT-Menu-Automation v-model="item.processAutomation" @change="save" />
                                <v-btn icon small @click="processCustomerOrders" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Process All Unprocessed Customer Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn icon small @click="processAllCustomerOrders" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item> -->
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                </template>
            </BT-Entity>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Courier Selecting</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'courier-pointers' }" title="Manage Automatic Courier Selecting">
                                    <v-icon small>mdi-routes</v-icon>
                                </v-btn>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.courierSelectionGuideAsSupplier)"
                                    v-model="item.courierSelectionTriggerAsSupplier"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon 
                                    v-model="item.courierSelectionGuideAsSupplier"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>
        <template v-slot:itemActions="{ item }">
            <BT-Single-Action
                v-if="item.isConfirmed && item.fulfillerID != null && !item.isDispatched"
                :loading="item.loadingCount > 0"
                title="Proceed to next step of purchase order - a stock consignment"
                @click="processPurchaseOrder(item)" />
        </template>

        <template v-slot:selectHeader="{ items }">
            <v-btn small icon @click.stop="processUnprocessedCustomerOrders(items)">
                <v-icon small>mdi-arrow-right-circle</v-icon>
            </v-btn>
        </template>

        <template v-slot:selectItem="{ item }">
            <v-btn v-if="!item.isDispatched" small icon @click.stop="item.isSelectedToProcess = !item.isSelectedToProcess">
                <v-icon v-if="item.isSelectedToProcess" small class="success--text">mdi-check</v-icon>
                <v-icon v-else small class="error--text">mdi-close</v-icon>
            </v-btn>
        </template>

        <template slot="actions">
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Customer-Orders-Blade',
    components: {
        BTSingleAction: () => import('~components/BT-Single-Action.vue'),
        // BTMenu: () => import('~components/BT-Menu.vue'),
        // BTMenuAutomation: () => import('~components/BT-Menu-Automation.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    data: function() {
        return {
            bladeData: null,
            loadingMsg: null,
            msg: null,
            selectMany: false
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        getNewBladeData(bladeData) {
            return {
                agreementID: bladeData.data.agreementID
            }
        },
        getSelectBladeData(bladeData, item) {
            return {
                agreementID: item.supplyAgreementID
            }
        },
        // async processCustomerOrders() {
        //     this.loadingMsg = 'Processing';

        //     try {
        //         await this.$BlitzIt.store.post('ordering-actions', { functionName: 'ProcessCustomerOrders' });
        //     }
        //     catch (err) {
        //         this.msg = this.extractErrorDescription(err);
        //     }
        //     finally {
        //         this.loadingMsg = null;
        //     }
        // },
        // async processAllCustomerOrders() {
        //     this.loadingMsg = 'Processing';

        //     try {
        //         await this.$BlitzIt.store.post('ordering-actions', { functionName: 'ProcessAllCustomerOrders' });
        //     }
        //     catch (err) {
        //         this.msg = this.extractErrorDescription(err);
        //     }
        //     finally {
        //         this.loadingMsg = null;
        //     }
        // },
        async processPurchaseOrder(po) {
            try {
                po.loadingCount += 1;
                
                var res = await this.$BlitzIt.api.patch('fulfiller-orders', {
                    id: po.id,
                    isProcessed: true,
                    rowVersion: po.rowVersion
                });

                po.isDispatched = true;
                po.rowVersion = res.data.data.rowVersion;
            }
            catch (err) {
                po.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                po.loadingCount -= 1;
            }
        },
        async processUnprocessedCustomerOrders(list) {
            try {
                var fList = list.filter(z => !z.isDispatched && z.isSelectedToProcess);
                for (let i = 0; i < fList.length; i++) {
                    const order = fList[i];
                    this.loadingMsg = `Processing ${i + 1} of ${fList.length} customer orders`;
                    await this.processPurchaseOrder(order);
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pullOrders(orders) {
            return orders.map(x => Object.assign({}, x, { isSelectedToProcess: false }))
        },
        selectUnprocessedOrders(orders) {
            orders.forEach(o => {
                if (!o.isDispatched) {
                    o.isSelectedToProcess = true;
                }
                else {
                    o.isSelectedToProcess = false;
                }
            })
            
            this.selectMany = true;
        }
    }
}
</script>